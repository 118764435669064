angular.module('app.controllers').controller('SearchCtrl', ['$scope', '$rootScope', 'Table', '$location', 'APIReadTable', 'APIRead', '$q'].append(function(self, root, Table, $location, APIReadTable, APIRead, $q) {
  var params;
  params = $location.search();
  self.loadData = function() {
    var s1, s2, s3;
    s1 = APIRead.query({
      entry_type: 'exhibitors',
      limit: 20,
      search: self.term
    });
    s2 = APIRead.query({
      entry_type: 'programs',
      limit: 20,
      search: self.term,
      reverse: false
    });
    s3 = APIRead.query({
      entry_type: 'novelties',
      limit: 12,
      search: self.term
    });
    return $q.all([s1, s2, s3]).then(function(responses) {
      self.exhibitors = responses[0];
      self.slots = responses[1];
      return self.novelties = responses[2];
    }).catch(function(response) {
      return UI.notify(false, response.data.error);
    });
  };
  self.term = params.q || null;
  if (self.term != null) {
    return self.loadData();
  }
}));

angular.module('app.controllers').controller('SearchBoxCtrl', ['$scope', '$rootScope', 'Table', '$location', 'APIReadTable', 'APIRead'].append(function(self, root, Table, $location, APIReadTable, APIRead) {
  var cookiesName, loadData, params, ref, resetData, tableName;
  params = $location.search();
  self.initSearch = function(page) {
    var dropdownEl;
    self.page = page;
    dropdownEl = document.getElementById(`${self.page}SF`);
    if (dropdownEl) {
      self.dropdown = bootstrap.Dropdown.getOrCreateInstance(dropdownEl);
      dropdownEl.addEventListener('show.bs.dropdown', function(event) {
        return self.$applyAsync();
      });
      return dropdownEl.addEventListener('focusout', function(event) {
        //self.dropdown.hide() if event.relatedTarget?.classList.contains('form-control') || !event.relatedTarget || event.relatedTarget?.tagName in ["BODY"]
        return self.$applyAsync();
      });
    }
  };
  self.$on('onCloseSearch', function(event, page) {
    if (self.page !== page) {
      return self.dropdown.hide();
    }
  });
  self.searchKey = function($event) {
    var ref, ref1;
    if ($event.keyCode === 13) {
      self.applyTerm();
      return (ref = self.dropdown) != null ? ref.hide() : void 0;
    } else {
      return (ref1 = self.dropdown) != null ? ref1.show() : void 0;
    }
  };
  self.hideDrop = function() {
    var ref;
    return (ref = self.dropdown) != null ? ref.hide() : void 0;
  };
  self.onSearchTextChange = function() {
    return loadData();
  };
  self.lvls = {
    exhibitors: 'Aussteller',
    programs: 'Programm',
    novelties: 'Neuheiten'
  };
  (resetData = function() {
    self.data = null;
    self.dataLoad = false;
    return self.term = '';
  })();
  tableName = 'c.sea.' + (((ref = root.user) != null ? ref.shortcode : void 0) || 'test');
  cookiesName = 't.' + tableName;
  return loadData = function() {
    return APIRead.get({
      search: self.term,
      limit: 50,
      entry_type: "all",
      full: true,
      page: self.page,
      reverse: false
    }, function(data) {
      return self.data = data;
    }, function(response) {
      self.data = null;
      return UI.notify(false, response.data.error);
    });
  };
}));
