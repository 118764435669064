angular.module('app.controllers').controller 'SearchCtrl', [
  '$scope', '$rootScope', 'Table', '$location', 'APIReadTable', 'APIRead', '$q'
].append (self, root, Table, $location, APIReadTable, APIRead, $q) ->

  params = $location.search()

  self.loadData = () ->
    s1 = APIRead.query {entry_type: 'exhibitors', limit: 20, search: self.term}
    s2 = APIRead.query {entry_type: 'programs', limit: 20, search: self.term, reverse: false}
    s3 = APIRead.query {entry_type: 'novelties', limit: 12, search: self.term}

    $q.all([s1, s2, s3])
      .then (responses) ->
        self.exhibitors = responses[0]
        self.slots      = responses[1]
        self.novelties  = responses[2]
      .catch (response) ->
        UI.notify false, response.data.error

  self.term = params.q || null
  self.loadData() if self.term?




angular.module('app.controllers').controller 'SearchBoxCtrl', [
  '$scope', '$rootScope', 'Table', '$location', 'APIReadTable', 'APIRead'
].append (self, root, Table, $location, APIReadTable, APIRead) ->

  params = $location.search()

  self.initSearch = (page) ->
    self.page = page

    dropdownEl = document.getElementById("#{self.page}SF")
    if dropdownEl
      self.dropdown = bootstrap.Dropdown.getOrCreateInstance(dropdownEl)

      dropdownEl.addEventListener('show.bs.dropdown', (event) ->
        self.$applyAsync()
      )

      dropdownEl.addEventListener('focusout', (event) ->
        #self.dropdown.hide() if event.relatedTarget?.classList.contains('form-control') || !event.relatedTarget || event.relatedTarget?.tagName in ["BODY"]
        self.$applyAsync()
      )

  self.$on 'onCloseSearch', (event, page) ->
    self.dropdown.hide() if self.page != page

  self.searchKey = ($event) ->
    if $event.keyCode == 13
      self.applyTerm()
      self.dropdown?.hide()
    else
      self.dropdown?.show()
  
  self.hideDrop = () ->
    self.dropdown?.hide()

  self.onSearchTextChange = () ->
    loadData()

  self.lvls = 
    exhibitors: 'Aussteller'
    programs: 'Programm'
    novelties: 'Neuheiten'

  (resetData = () ->
    self.data = null
    self.dataLoad = false
    self.term = ''
  )()


  tableName   = 'c.sea.' + (root.user?.shortcode || 'test')
  cookiesName = 't.' + tableName


  loadData = () ->
    APIRead.get {search: self.term, limit: 50, entry_type: "all", full: true, page: self.page, reverse: false}, (data) ->
      self.data = data
    , (response) ->
      self.data = null
      UI.notify false, response.data.error
  